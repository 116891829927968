<template>
  <v-card width="100%" class="py-6"
          outlined
  >
    <v-card-title class="font-weight-bold mb-5 text-h5">
      طريقة الدفع
    </v-card-title>
    <div class="px-10 text-h7">
      <v-radio-group v-model="payment_method">
        <v-row>
          <v-col lg="6" v-for="method in paymentMethods"
                 :key="method">
            <v-radio
                class="text--darken-1 font-weight-bold"
                color="amber lighten-1"
                :label="$t(`paymentMethods.${method.toLowerCase()}`)"
                :value="method"
            ></v-radio>
            <v-file-input v-if="method.toLowerCase() === 'bank-transfer'"
                          label="صورة التحويل"
                          color="amber lighten-1"
                          accept="image/* pdf/*"
                          :disabled="payment_method.toLowerCase() !== 'bank-transfer'"
                          prepend-icon="mdi-file"
                          @change="uploadImage"
                          :loading="loading"
            ></v-file-input>
          </v-col>
        </v-row>
      </v-radio-group>
    </div>
    <slot></slot>
  </v-card>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "PaymentMethodCard",
  data() {
    return {
      paymentMethods: [
        "CASH",
        "BANK-TRANSFER"
      ],
      loading: false
    }
  },
  computed: {
    payment_method: {
      get() {
        return this.$store.state.order.payment_method;
      },
      set(value) {
        this.$store.commit("updateOrder", {prop: "payment_method", value});
      }
    },
    payment_proof_image_path: {
      get() {
        return this.$store.state.order.payment_proof_image_path;
      },
      set(value) {
        this.$store.commit("updateOrder", {prop: "payment_proof_image_path", value});
      }
    }
  },
  methods: {
    ...mapActions({
      uploadPaymentProof: "uploadPaymentProof"
    }),
    uploadImage(file) {
      this.loading = true;
      let imageFormData = new FormData();
      imageFormData.append("file", file);
      this.uploadPaymentProof(imageFormData).then(() => {
        this.loading = false;
        this.$toast('تم رفع الصورة بنجاح', {
          color: 'green',
          x: 'center',
          y: 'top',
          dismissable: true,
          queueable: true
        });
      }).catch((err) => {
        this.loading = false;
        this.$toast(err.data.first_error, {
          color: 'red',
          x: 'center',
          y: 'top',
          dismissable: true,
          queueable: true
        });
      });
    }
  }
}
</script>

<style scoped>

</style>