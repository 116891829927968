export const registration = (state) => {
  let registration = {};
  for(let prop in state.registration) {
    if(prop === "phone") {
      registration[prop] = "+966" + state.registration[prop];
      continue;
    }
    registration[prop] = state.registration[prop];
  }
  return registration;
};