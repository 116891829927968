export const updateOrder = (state, {prop, value}) => {
  state.order[prop] = value;
};

export const updateIsSuccessfulOrderDialogOpened = (state, value) => {
  state.isSuccessfulOrderDialogOpened = value;
};

export const emptyCart = (state) => {
  state.cart = [];
  localStorage.setItem("cart", JSON.stringify(state.cart));
};

export const fillCartFromLocalStorage = (state) => {
  let storageCart = JSON.parse(localStorage.getItem("cart"));
  state.cart = storageCart ? storageCart : [];
};

export const emptyProducts = (state) => {
  state.products = [];
};

export const updateIsCalculating = (state, value) => {
  state.isCalculating = value;
};