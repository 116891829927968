export default {
  data() {
    return {
      promise: Promise.resolve(),
      savedTimestamp: null,
      timer: null
    }
  },
  methods: {
    lazyRequest(request) {
      let timestampNow = new Date().getTime() / 1000; // seconds
      let elapsedTime = timestampNow - this.savedTimestamp; // seconds
      if(this.savedTimestamp && elapsedTime < 1) {
        clearTimeout(this.timer);
      }
      this.savedTimestamp = new Date().getTime() / 1000; // seconds
      this.timer = setTimeout(() => {
        Promise.all([this.promise]).then(() => {
          this.promise = request();
        })
      }, 1000);
    }
  }
}