<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
          dark
          color="primary"
      >
        <v-btn
            icon
            dark
            @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div v-if="order">
        <div class="d-flex px-5 py-10 justify-space-between">
          <div class="">
            <span class="font-weight-bold ml-3">#</span>
            <span>{{order.id}}</span>
          </div>
          <div>
            <span class="font-weight-bold ml-3">كود الطلب</span>
            <span>{{order.reference_code}}</span>
          </div>
          <div>
            <span class="font-weight-bold ml-3">الحالة</span>
            <span>{{$t(`statuses.${order.status}`)}}</span>
          </div>
          <div>
            <span class="font-weight-bold ml-3">طريقة الدفع</span>
            <span>{{$t(`paymentMethods.${order.payment_method.toLowerCase()}`)}}</span>
          </div>
          <div>
            <span class="font-weight-bold ml-3">تاريخ الانشاء</span>
            <span>{{order.created_at}}</span>
          </div>
          <div>
            <v-btn
                :href="order.invoice_url"
                target="_blank"
            >
              الفاتورة
            </v-btn>
          </div>
        </div>
        <div>
          <v-row>
            <v-col cols="12">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-right">
                      المنتج
                    </th>
                    <th class="text-right">
                      السعر
                    </th>
                    <th class="text-center">
                      الكمية
                    </th>
                    <th class="text-left">
                      الاجمالي
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="item in order.products"
                      :key="item.id"
                  >
                    <td>{{ item.product.name }} ( {{item.product.reference_code}} )</td>
                    <td>{{ item.price }}</td>
                    <td>{{ item.quantity }}</td>
                    <td class="text-left">
                      {{ item.price * item.quantity }}
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-col cols="6">
              <v-row class="px-4">
                <v-col cols="6">
                  <div class="d-flex flex-column">
                    <div class="py-3 font-weight-bold">الصافي</div>
                    <div class="py-3 font-weight-bold">الضريبة</div>
                    <v-divider></v-divider>
                    <div class="py-3 font-weight-bold">الاجمالي</div>
                  </div>
                </v-col>
                <v-col cols="6" class="text-left">
                  <div class="d-flex flex-column">
                    <div class="py-3">{{order.receipt.subtotal}}</div>
                    <div class="py-3">{{order.receipt.tax}}</div>
                    <v-divider></v-divider>
                    <div class="py-3">{{order.receipt.total_price}}</div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="text-center" v-else>
        <v-progress-circular
            :size="50"
            color="amber"
            indeterminate
        ></v-progress-circular>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "OrderDetailsDialog",
  data() {
    return {
      order: null,
      dialog: false
    }
  },
  props: {
    orderId: {
      required: true
    }
  },
  mounted() {
    this.dialog = true;
    this.showOrder(this.orderId);
  },
  methods: {
    ...mapActions({
      getOrder: "getOrder",
    }),
    closeDialog() {
      this.dialog = false;
      this.$emit('close');
      location.hash = "";
    },
    showOrder(id) {
      this.getOrder(id).then((order) => {
        this.order = order;
      });
    }
  }
}
</script>

<style scoped>

</style>