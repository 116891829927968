import request from "../helpers/request";
import apiURLs from "../helpers/apiURLs";

export const fetchProducts = ({state}, filter) => {
  return new Promise((resolve, reject) => {
    request.constructAuthorizedRequest()({
      url: apiURLs.products(),
      method: "get",
      params: {
        ...filter
      }
    }).then((response) => {
      state.products = response.data.data;
      resolve(response.data.data);
    }).catch((error) => {
      reject(error.response);
      console.log(error.toString(), error.response);
    });
  });
};

export const calculateOrder = ({state, getters}) => {
  return new Promise((resolve, reject) => {
    request.constructAuthorizedRequest()({
      url: apiURLs.calculateReceipt(),
      method: "post",
      data: {products: getters.products}
    }).then((response) => {
      state.receipt = response.data.data;
      resolve(response.data.data);
    }).catch((error) => {
      reject(error.response);
      console.log(error.toString(), error.response);
    });
  });
};

export const uploadPaymentProof = ({state}, imageFormData) => {
  return new Promise((resolve, reject) => {
    request.constructAuthorizedRequest()({
      url: apiURLs.uploadPaymentProof(),
      method: "post",
      data: imageFormData
    }).then((response) => {
      state.order.payment_proof_image_path = response.data.path;
      resolve(response.data.data);
    }).catch((error) => {
      reject(error.response);
      console.log(error.toString(), error.response);
    });
  });
};

export const createOrder = ({getters, rootState}) => {
  return new Promise((resolve, reject) => {
    request.constructAuthorizedRequest()({
      url: apiURLs.createOrder(rootState._auth.user.id),
      method: "post",
      data: getters.order
    }).then((response) => {
      resolve(response.data.data);
    }).catch((error) => {
      reject(error.response);
      console.log(error.toString(), error.response);
    });
  });
};

export const fetchOrders = ({state}, {page}) => {
  return new Promise((resolve, reject) => {
    request.constructAuthorizedRequest()({
      url: apiURLs.orders(),
      method: "get",
      params: {
        page
      }
    }).then((response) => {
      state.orders = response.data.data;
      resolve(response.data);
    }).catch((error) => {
      reject(error.response);
      console.log(error.toString(), error.response);
    });
  });
};

export const getOrder = ({state}, id) => {
  return new Promise((resolve, reject) => {
    request.constructAuthorizedRequest()({
      url: apiURLs.orderById(id),
      method: "get"
    }).then((response) => {
      state.order = response.data.data;
      resolve(response.data.data);
    }).catch((error) => {
      reject(error.response);
      console.log(error.toString(), error.response);
    });
  });
};