<template>
  <v-row>
    <v-col cols="12">
      <order-card :loading="isCalculating"></order-card>
      <address-card class="mt-3"></address-card>
      <payment-method-card class="mt-3"></payment-method-card>
      <div class="text-center my-10">
        <v-btn
            class="font-weight-bold"
            depressed
            width="80%"
            height="50"
            color="amber lighten-1"
            :loading="loading"
            @click="checkout"
        >
          اتمام الشراء
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import OrderCard from "../components/checkout/OrderCard";
import AddressCard from "../components/checkout/AddressCard";
import PaymentMethodCard from "../components/checkout/PaymentMethodCard";
import {mapActions, mapState, mapMutations} from "vuex";
import {required} from "vuelidate/lib/validators";

export default {
  name: "Checkout",
  components: {PaymentMethodCard, AddressCard, OrderCard},
  data() {
    return {
      loading: false,
    }
  },
  validations: {
    lat: {
      required
    },
    lng: {
      required
    }
  },
  computed: {
    ...mapState({
      cart: state => state.cart,
      isCalculating: state => state.isCalculating,
      lat: state => state.order.latitude,
      lng: state => state.order.longitude
    }),
    isSuccessfulOrderDialogOpened: {
      get() {
        return this.$store.state.isSuccessfulOrderDialogOpened;
      },
      set(value) {
        this.$store.commit("updateIsSuccessfulOrderDialogOpened", value);
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if(!vm.cart.length) {
        next({name: "cart"})
      }
      next();
    })
  },
  methods: {
    ...mapActions({
      createOrder: "createOrder",
      fetchUser: "_auth/fetchUser"
    }),
    ...mapMutations({
      emptyCart: "emptyCart"
    }),
    checkout() {
      this.$v.$touch();
      if(this.$v.$invalid) {
        this.$toast("من فضلك اختار العنوان من علي الخريطة", {
          color: 'red',
          x: 'center',
          y: 'top',
          dismissable: true,
          queueable: true
        });
        return;
      }
      this.loading = true;
      this.createOrder().then((data) => {
        this.loading = false;
        this.$emit("checkout", data);
        this.isSuccessfulOrderDialogOpened = true;
        this.emptyCart();
        this.fetchUser();
      }).catch((err) => {
        this.loading = false;
        this.$toast(err.data.first_error, {
          color: 'red',
          x: 'center',
          y: 'top',
          dismissable: true,
          queueable: true
        });
      });
    }
  }
}
</script>

<style scoped>

</style>