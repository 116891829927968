<template>
  <v-row justify="center" justify-lg="start">
    <v-skeleton-loader
        height="500px"
        v-bind="attrs"
        type="card"
    ></v-skeleton-loader>
  </v-row>
</template>

<script>
export default {
  name: "OrderReceiptSkeletonLoader",
  data() {
    return {
      attrs: {
        outlined: true,
        width: "100%"
      },
    }
  }
}
</script>

<style scoped>

</style>