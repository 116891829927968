<template>
  <div class="text-center">
    <h1>404</h1>
    <v-btn
        depressed
        class="font-weight-bold"
        color="amber lighten-1"
        @click="$router.replace({name: 'home'})"
    >
      الرئيسية
    </v-btn>

  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>