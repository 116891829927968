import request from "../../../helpers/request";
import apiURLs from "../../../helpers/apiURLs";

export const login = ({state}) => {
  return new Promise((resolve, reject) => {
    request.axios({
      url: apiURLs.login(),
      method: "post",
      data: state.login
    }).then((response) => {
      state.user = response.data.data.wholesale_user;
      resolve();
      localStorage.setItem("access-token", response.data.data.token);
    }).catch((error) => {
      reject(error.response);
      console.log(error.toString(), error.response);
    });
  });
};

export const logout = () => {
  return new Promise((resolve, reject) => {
    request.constructAuthorizedRequest()({
      url: apiURLs.logout(),
      method: "post"
    }).then(() => {
      resolve();
      localStorage.removeItem("access-token");
    }).catch((error) => {
      reject(error.response);
      console.log(error.toString(), error.response);
    });
  });
};

export const register = ({getters}) => {
  return new Promise((resolve, reject) => {
    request.axios({
      url: apiURLs.register(),
      method: "post",
      data: getters.registration
    }).then((response) => {
      resolve(response.data.data);
    }).catch((error) => {
      reject(error.response);
      console.log(error.toString(), error.response);
    });
  });
};

export const fetchUser = ({state}) => {
  return new Promise((resolve, reject) => {
    request.constructAuthorizedRequest()({
      url: apiURLs.user(),
      method: "get"
    }).then((response) => {
      state.user = response.data.data;
      resolve(response);
    }).catch((error) => {
      reject(error.response);
      console.log(error.toString(), error.response);
    });
  });
};