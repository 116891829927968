<template>
  <div>
    <v-card-title class="d-block text-center text-h6 font-weight-bold mb-4">
      تسجيل الدخول
    </v-card-title>
    <login-form ref="loginForm"></login-form>
    <v-card-actions class="d-flex justify-center">
      <v-btn
          class="font-weight-bold text-h7"
          depressed
          width="50%"
          height="50"
          color="amber lighten-1"
          @click="getLoggedIn"
          :loading="loading"
      >
        دخول
      </v-btn>
    </v-card-actions>
    <div>
      <span class="ml-2">عميل جديد ؟</span>
      <router-link :to="{name: 'register'}">سجل معنا</router-link>
    </div>
  </div>
</template>

<script>
import LoginForm from "./Components/LoginForm";
import {mapActions} from "vuex";

export default {
  name: "Login",
  components: {LoginForm},
  data() {
    return {
      loading: false
    }
  },
  methods: {
    ...mapActions({
      login: "_auth/login"
    }),
    getLoggedIn() {
      this.$refs.loginForm.$v.$touch();
      if(this.$refs.loginForm.$v.$invalid) return;
      this.loading = true;
      this.login().then(() => {
        this.loading = false;
        this.$router.replace({name: "home"});
      }).catch((err) => {
        this.loading = false;
        this.$toast(err.data.first_error, {
          color: 'red',
          x: 'center',
          y: 'top',
          dismissable: true,
          queueable: true
        });
      });
    }
  }
}
</script>

<style scoped>

</style>