<template>
  <form>
    <v-text-field
        v-model="address"
        label="العنوان"
        required
    ></v-text-field>
  </form>
</template>

<script>
export default {
  name: "AddressForm",
  computed: {
    address: {
      get() {
        return this.$store.state.order.address;
      },
      set(value) {
        this.$store.commit("updateOrder", {prop: "address", value});
      }
    }
  }
}
</script>

<style scoped>

</style>