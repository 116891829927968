import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

import ar from '../locales/ar';
import en from '../locales/en';

const messages = {ar, en};

export default new VueI18n({
    locale: document.documentElement.lang,
    messages
});

