import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import state from "./state";
import * as mutations from "./mutations";
import * as actions from "./actions";
import * as getters from "./getters";

// modules
import Auth from "../pages/Auth/store";

export default new Vuex.Store({
  modules: {
    _auth: Auth,
  },
  state,
  mutations,
  actions,
  getters
})