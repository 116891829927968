<template>
  <v-container>
    <content-header v-if="crumbs && crumbs.length > 1"></content-header>
    <router-view v-if="user"></router-view>
  </v-container>
</template>

<script>
import ContentHeader from "./header/ContentHeader";
import {mapActions, mapState} from "vuex";

export default {
  name: "layout",
  components: {ContentHeader},
  created() {
    this.fetchUser().then(() => {
    }).catch((err) => {
      this.$toast(err.data.first_error, {
        color: 'red',
        x: 'center',
        y: 'top',
        dismissable: true,
        queueable: true
      });
    });
  },
  computed: {
    ...mapState({
      user: state => state._auth.user
    }),
    crumbs() {
      return this.$route.meta.crumbs;
    }
  },
  methods: {
    ...mapActions({
      fetchUser: "_auth/fetchUser"
    })
  }
}
</script>

<style scoped>

</style>