export default {
  routes: {
    home: "الرئيسية",
    cart: "عربة التسوق",
    profile: "الحساب الشخصي",
    orders_history: "طلباتي",
    checkout: "اتمام عملية الشراء",
    address: "العنوان"
  },
  labels: {
    logout: "الخروج"
  },
  paymentMethods: {
    cash: "كاش",
    "bank-transfer": "تحويل بنكي"
  },
  statuses: {
    new: "جديد",
    approved: "معتمد",
    confirmed: "مؤكد",
    'ready-to-ship': "تم التحضير",
    shipping: "تم الشحن",
    cancelled: "ملغي",
    'pricing-unavailable': "غير متوفر",
    'delivered': "تم التوصيل"
  }
}