<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="orders"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        :server-items-length="total"
        hide-default-footer
        class="elevation-1"
        @page-count="pageCount = $event"
    >
      <template v-slot:item.id="{ item }">
        <a :href="`#${item.id}`" @click="showOrderDetails(item.id)">
          {{ item.id }}
        </a>
      </template>
      <template v-slot:item.status="{ item }">
          {{ $t(`statuses.${item.status}`) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
            :href="item.invoice_url"
            icon target="_blank"
        >
          <v-icon>
            mdi-receipt
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
          v-model="page"
          :length="pageCount"
      ></v-pagination>
    </div>
    <order-details-dialog v-if="showDetails" @close="showDetails = false"
                          :order-id="selectedId">

    </order-details-dialog>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import OrderDetailsDialog from "../components/OrderDetailsDialog";
export default {
  name: "OrdersHistory",
  components: {OrderDetailsDialog},
  mounted() {
    if(location.hash.slice(1)){
      this.showOrderDetails(location.hash.slice(1));
    }
    this.fetchOrders({page: this.page}).then((data) => {
      this.total = data.meta.total;
      this.itemsPerPage = data.meta.per_page;
    }).catch(() => {
    });
  },
  methods: {
    ...mapActions({
      fetchOrders: "fetchOrders"
    }),
    showOrderDetails(itemId) {
      this.selectedId = location.hash.slice(1) ? location.hash.slice(1) : itemId;
      this.showDetails = true;
    }
  },
  computed: {
    ...mapState({
      orders: state => state.orders
    })
  },
  data() {
    return {
      selectedId: null,
      showDetails: false,
      page: 1,
      total: 0,
      pageCount: 0,
      itemsPerPage: 15,
      headers: [
        {
          text: 'رقم الطلب',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'كود الطلب',
          align: 'start',
          sortable: false,
          value: 'reference_code',
        },
        {
          text: 'الحالة',
          align: 'start',
          sortable: false,
          value: 'status',
        },
        {text: 'تاريخ الطلب', value: 'created_at'},
        {text: '', value: 'actions', sortable: false},
      ]
    }
  },
  watch: {
    page(page) {
      this.fetchOrders({page}).then((data) => {
        this.total = data.meta.total;
        this.itemsPerPage = data.meta.per_page;
      }).catch(() => {
      });
    }
  }
}
</script>

<style scoped>

</style>