let apiUrl;
let defaultApiUrl = "https://staging-api.atlobha.com";

if (process.env.VUE_APP_API_BASE_URL) {
  apiUrl = process.env.VUE_APP_API_BASE_URL;
} else {
  apiUrl = defaultApiUrl;
}
const makeUrl = (route) => {
  return apiUrl + "/api/wholesale" + route;
};

export default {
  login: () => {
    return makeUrl("/login")
  },
  logout: () => {
    return makeUrl("/logout")
  },
  register: () => {
    return makeUrl("/register")
  },
  products: () => {
    return makeUrl("/products")
  },
  calculateReceipt: () => {
    return makeUrl("/orders/calculate-receipt")
  },
  user: () => {
    return makeUrl("/me")
  },
  uploadPaymentProof: () => {
    return makeUrl("/payment-proof/upload")
  },
  createOrder: (id) => {
    return makeUrl(`/users/${id}/orders`)
  },
  orders: () => {
    return makeUrl("/orders")
  },
  orderById: (id) => {
    return makeUrl(`/orders/${id}`)
  }
}
