<template>
  <v-row>
    <v-col md="6" cols="12" class="login-bg pa-0">
      <img class="login-image" src="../../assets/img/ic_pattern-yellow-2x.png">
      <img class="logo" src="../../assets/img/atlobha-logo.png">
      <img class="login-info-img" src="../../assets/img/ic_login-info_graphic-3x.png">
      <p class="app-name">اطلبها Atlobha</p>
    </v-col>
    <v-col md="6" cols="12" class="auth-form d-flex align-center justify-center pa-0">
      <v-card :width="$vuetify.breakpoint.mdAndDown ? '95%' : '60%'"
              class="py-12 text-center" elevation="4" shaped
      >
        <router-view></router-view>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AuthLayout"
}
</script>

<style scoped>

</style>