<template>
  <div>
    <v-card v-if="receipt" width="100%" class="pb-6"
            outlined
            :loading="loading"
    >
      <v-card-title class="font-weight-bold mb-5 text-h5">
        اجمالي الطلب
      </v-card-title>
      <div class="px-10 text-h7">
        <v-row class="py-4">
          <v-col cols="6" class="font-weight-bold">
            اجمالي السعر
          </v-col>
          <v-col cols="6" class="text-left">
            {{receipt.subtotal}}
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="py-4">
          <v-col cols="6" class="font-weight-bold">
            الضريبة
          </v-col>
          <v-col cols="6" class="text-left">
            {{receipt.tax}}
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="py-4 text-h6">
          <v-col cols="6" class="font-weight-bold">
            الاجمالي
          </v-col>
          <v-col cols="6" class="text-left">
            {{receipt.total_price}}
          </v-col>
        </v-row>
      </div>
      <slot></slot>
    </v-card>
    <order-receipt-skeleton-loader v-else></order-receipt-skeleton-loader>
  </div>
</template>

<script>
import {mapState} from "vuex";
import OrderReceiptSkeletonLoader from "../OrderReceiptSkeletonLoader";

export default {
  name: "OrderCard",
  components: {OrderReceiptSkeletonLoader},
  props: {
    loading: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapState({
      receipt: state => state.receipt
    })
  }
}
</script>

<style scoped>

</style>