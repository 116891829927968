export default {
  products: [],
  cart: [],
  receipt: null,
  order: {
    payment_method: "CASH",
    address: null,
    latitude: null,
    longitude: null,
    payment_proof_image_path: null
  },
  isSuccessfulOrderDialogOpened: false,
  orders: [],
  isCalculating: false
}