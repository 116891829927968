<template>
  <div>
    <v-breadcrumbs large :items="crumbs">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="{name: item}" replace exact>
          {{$t(`routes.${item}`)}}
        </v-breadcrumbs-item>
      </template>
      <template v-slot:divider>
        <v-icon>mdi-chevron-left</v-icon>
      </template>
    </v-breadcrumbs>
    <div class="text-h4 pb-7 font-weight-bold">
      {{$t(`routes.${$route.name}`)}}
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentHeader",
  computed: {
    crumbs() {
      return this.$route.meta.crumbs;
    }
  }
}
</script>

<style scoped>

</style>