<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col
             lg="6"
             sm="12"
      >
        <v-autocomplete
            v-model="selected"
            :items="products"
            :loading="loading"
            :search-input.sync="search"
            auto-select-first
            autofocus
            @change="addToCart"
            color="black"
            hide-no-data
            no-filter
            hide-selected
            item-text="name"
            label="قطع غيار السيارات"
            placeholder="ابدأ البحث عن القطعة بالرقم المرجعي"
            prepend-icon="mdi-database-search"
            return-object
        >
          <template v-slot:item="{ on, attrs, item }">
            <div class="d-flex flex-column py-2">
              <div class="font-weight-medium">{{item.name}}</div>
              <div class="grey--text lighten-3">{{item.reference_code}}</div>
            </div>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col lg="6"
             sm="12">
        <v-file-input v-if="!isSuccessfulOrderDialogOpened"
            color="black"
                      placeholder="اضف المنتجات بملف اكسيل"
                      accept=".xlsx, .csv, .xls"
                      prepend-icon="mdi-file-excel"
                      @change="uploadExcelFile"
                      :loading="loadingExcel"
        >
        </v-file-input>
      </v-col>
    </v-row>
    <cart style="margin-top: 110px;"></cart>
    <checkout v-if="isCartEmpty" @checkout="(data) => {orderId = data.id}"></checkout>
    <successful-order v-if="isSuccessfulOrderDialogOpened"
                      :order-id="orderId">
    </successful-order>
  </v-container>
</template>

<script>
import Cart from "./Cart";
import lazyLoading from "../mixins/lazyLoading";
import {mapActions, mapMutations, mapState} from "vuex";
import Checkout from "./Checkout";
import SuccessfulOrder from "./SuccessfulOrder";
import {read} from "xlsx"
export default {
  name: "Home",
  components: {Checkout, Cart, SuccessfulOrder},
  mixins: [lazyLoading],
  data() {
    return {
      selected: null,
      search: null,
      loading: false,
      loadingExcel: false,
      orderId: null
    }
  },
  computed: {
    ...mapState({
      products: state => state.products,
      cart: state => state.cart,
      isSuccessfulOrderDialogOpened: state => state.isSuccessfulOrderDialogOpened
    }),
    isCartEmpty() {
      return !!this.cart.length;
    }
  },
  methods: {
    ...mapActions({
      fetchProducts: "fetchProducts"
    }),
    ...mapMutations({
      emptyCart: "emptyCart"
    }),
    searchSingleProduct(value) {
      if(!value) return;
      this.loading = true;
      this.searchProducts({reference_code: value}).then(() => {
        this.loading = false;
      }).catch(() => {this.loading = false})
    },
    searchProducts(param) {
      return new Promise((resolve, reject) => {
        this.lazyRequest(() => {
          this.fetchProducts(param).then((data) => {
            resolve(data);
          }).catch((err) => {
            reject(err);
          });
        });
      });
    },
    addToCart(item) {
      this.$nextTick(() => {
        this.selected = null;
      })
      this.$toast('تم اضافة المنتج لعربة التسوق', {
        color: 'green',
        x: 'center',
        y: 'top',
        dismissable: true,
        queueable: true
      });
      for (let cartItem of this.cart) {
        if (cartItem.id === item.id) {
          cartItem.quantity_to_purchase++;
          cartItem.total = cartItem.price * cartItem.quantity_to_purchase;
          return;
        }
      }
      item.quantity_to_purchase = 1;
      item.total = item.price;
      this.cart.push(item);
      this.saveCart();
    },
    saveCart() {
      localStorage.setItem("cart", JSON.stringify(this.cart));
    },
    getSheetCellCount(sheet) {
      let itemsCount = 0;
      Object.keys(sheet).forEach((el) => {
        let cellNumber = el.slice(1);
        if(!isNaN(cellNumber)) {
          itemsCount = parseInt(cellNumber) > itemsCount ? parseInt(cellNumber) : itemsCount;
        }
      });
      return itemsCount;
    },
    uploadExcelFile(file) {
      if(!file) return;
      /* eslint-disable */
      let reader = new FileReader();
      reader.onload = (file) => {
        let data = new Uint8Array(file.target.result);
        let workbook = read(data, {type: 'array'});
        let sheet = workbook.Sheets.Sheet1;

        // let itemsCount = sheet['!ref'].slice(-1);
        let items = {};
        for(let i = 2; i <= this.getSheetCellCount(sheet); i++) {
          let reference_code = sheet[`A${i}`].w;
          let quantity = sheet[`B${i}`].w;
          items[reference_code] = quantity;
        }
        this.loadingExcel = true;
        this.searchProducts({reference_codes: Object.keys(items)}).then((products) => {
          this.loadingExcel = false;
          this.$toast('تم اضافة المنتجات لعربة التسوق', {
            color: 'green',
            x: 'center',
            y: 'top',
            dismissable: true,
            queueable: true
          });
          this.emptyCart();
          for (let product of products) {
            product.quantity_to_purchase = items[product.reference_code];
            product.total = product.price * product.quantity_to_purchase;
            this.cart.push(product);
          }
          this.saveCart();
        }).catch(() => {this.loadingExcel = false;})
      };
      reader.readAsArrayBuffer(file);
    }
  },
  watch: {
    search(value) {
      this.searchSingleProduct(value);
    }
  }
}
</script>

<style scoped>

</style>