<template>
  <div>
    <v-card-title class="d-block text-center text-h6 font-weight-bold mb-4">
      إنشاء حساب
    </v-card-title>
    <register-form ref="registerForm"></register-form>
    <v-card-actions class="d-flex justify-center">
      <v-btn
          class="font-weight-bold text-h7"
          depressed
          width="50%"
          height="50"
          color="amber lighten-1"
          @click="registerUser"
          :loading="loading"
      >
        تسجيل
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import RegisterForm from "./Components/RegisterForm";
import {mapActions} from "vuex";
export default {
  name: "Register",
  components: {RegisterForm},
  data() {
    return {
      loading: false
    }
  },
  methods: {
    ...mapActions({
      register: "_auth/register"
    }),
    registerUser() {
      this.$refs.registerForm.$v.$touch();
      if(this.$refs.registerForm.$v.$invalid) return;
      this.loading = true;
      this.register().then(() => {
        this.loading = false;
        this.$router.replace({name: "login"});
        this.$toast('تم تسجيل الحساب بنجاح', {
          color: 'green',
          x: 'center',
          y: 'top',
          dismissable: true,
          queueable: true
        });
      }).catch((err) => {
        this.loading = false;
        this.$toast(err.data.first_error, {
          color: 'red',
          x: 'center',
          y: 'top',
          dismissable: true,
          queueable: true
        });
      });
    }
  }
}
</script>

<style scoped>

</style>