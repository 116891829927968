export const products = (state) => {
  return state.cart.map((x) => {
    let product = {};
    product.id = x.id;
    product.quantity = x.quantity_to_purchase;
    return product;
  })
};

export const order = (state) => {
  return {
    ...state.order,
    products: products(state)
  }
}