<template>
  <v-app>
    <router-view name="app-header"></router-view>
    <v-main class="grey lighten-5">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import {EventBus} from "./helpers/event";
import {mapMutations} from "vuex";
export default {
  name: 'App',
  created() {
    this.fillCartFromLocalStorage();
    EventBus.$on("unauthorized-request", () => {
      this.$toast("من فضلك سجل الدخول مره اخري", {
        color: 'red',
        x: 'center',
        y: 'top',
        dismissable: true,
        queueable: true
      });
      localStorage.removeItem("access-token");
      this.$router.push({name: "login"});
    });
  },
  methods: {
    ...mapMutations({
      fillCartFromLocalStorage: "fillCartFromLocalStorage"
    })
  }
};
</script>
