<template>
  <div>
    <div v-if="!isCartEmpty">
      <v-row>
        <v-col cols="12">
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-right">
                  المنتج
                </th>
                <th class="text-right">
                  السعر
                </th>
                <th class="text-center">
                  الكمية
                </th>
                <th class="text-left">
                  الاجمالي
                </th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="item in cart"
                  :key="item.id"
              >
                <td>
                  <span>{{ item.name }}</span>
                  <sub class="mt-2 d-block grey--text lighten-3">
                    {{item.reference_code}}
                  </sub>
                </td>
                <td>{{ item.price }}</td>
                <td style="width: 150px;">
                  <v-text-field v-model="item.quantity_to_purchase"
                                dense
                                hide-details
                                type="number"
                                hide-spin-buttons
                                single-line
                                outlined
                                append-icon="mdi-plus"
                                @input="changeQuantity(item)"
                                @click:append="increment(item)"
                                prepend-inner-icon="mdi-minus"
                                @click:prepend-inner="decrement(item)">
                  </v-text-field>
                </td>
                <td class="text-left">
                  {{ item.total }}
                </td>
                <td class="text-center">
                  <v-btn
                      color="grey darken-1"
                      fab
                      outlined
                      x-small
                      @click="removeCartItem(item)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
    <empty-cart v-else></empty-cart>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import EmptyCart from "../components/EmptyCart";

export default {
  name: "Cart",
  components: {EmptyCart},
  computed: {
    ...mapState({
      cart: state => state.cart
    }),
    isCartEmpty() {
      return !this.cart.length;
    },
    isCalculating: {
      get() {
          return this.$store.state.isCalculating;
      },
      set(value) {
        this.$store.commit("updateIsCalculating", value);
      }
    }
  },
  created() {
    if(this.isCartEmpty) return;
    this.calculateOrderReceipt();
  },
  methods: {
    ...mapActions({
      calculateOrder: "calculateOrder"
    }),
    calculateOrderReceipt() {
      this.isCalculating = true;
      this.calculateOrder().then(() => {
        this.isCalculating = false;
      }).catch(() => {
        this.isCalculating = false;
      });
    },
    removeCartItem(item) {
      for(let i = 0; i < this.cart.length; i++) {
        let cartItem = this.cart[i];
        if(cartItem.id === item.id) {
          this.cart.splice(i, 1);
          localStorage.setItem("cart", JSON.stringify(this.cart));
          break;
        }
      }
    },
    increment(item) {
      for(let i = 0; i < this.cart.length; i++) {
        let cartItem = this.cart[i];
        if(cartItem.id === item.id) {
          cartItem.quantity_to_purchase = parseInt(cartItem.quantity_to_purchase, 10) + 1;
          cartItem.total = cartItem.price * cartItem.quantity_to_purchase;
          this.cart.splice(i, 1, cartItem)
          this.save()
          break;
        }
      }
    },
    decrement(item) {
      if(item.quantity_to_purchase === 1) return;
      for(let i = 0; i < this.cart.length; i++) {
        let cartItem = this.cart[i];
        if(cartItem.id === item.id) {
          cartItem.quantity_to_purchase = parseInt(cartItem.quantity_to_purchase, 10) - 1;
          cartItem.total = cartItem.price * cartItem.quantity_to_purchase;
          this.cart.splice(i, 1, cartItem)
          this.save()
          break;
        }
      }
    },
    save() {
      localStorage.setItem("cart", JSON.stringify(this.cart));
    },
    changeQuantity(item) {
      for(let i = 0; i < this.cart.length; i++) {
        let cartItem = this.cart[i];
        if(cartItem.id === item.id) {
          cartItem.quantity_to_purchase = !item.quantity_to_purchase ? 1 : parseInt(cartItem.quantity_to_purchase, 10);
          cartItem.total = cartItem.price * cartItem.quantity_to_purchase;
          this.cart.splice(i, 1, cartItem)
          this.save();
          break;
        }
      }
    },
  },
  watch: {
    cart(value) {
      if(!value.length) {
        return;
      }
      this.calculateOrderReceipt();
    }
  }
}
</script>

<style lang="scss">
.v-data-table__wrapper {
  thead {
    background: #F7F7F7;
  }

  border: 1px solid #ebebeb;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th,
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:not(.v-data-table__mobile-row) {
  input[type="number"] {
    text-align: center;
    color: black;
    font-weight: 600;
  }

  border-bottom: 1px solid #ebebeb;
  font-size: 16px;
  padding: 12px 18px;
}
</style>