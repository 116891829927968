<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="800px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            class="font-weight-bold"
            color="amber lighten-1"
            v-bind="attrs"
            v-on="on"
        >
          <span v-if="!isLatLng">اختار العنوان من علي الخريطة</span>
          <span v-else>تغيير</span>
        </v-btn>
        <div v-if="!isLatLng" class="red--text validation-message">(اجباري)</div>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">تعديل العنوان</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <address-form></address-form>
            <address-map v-model="addressPosition"></address-map>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="chooseRegisteredAddress"
          >
            الغاء
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="save"
          >
            حفظ
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AddressForm from "./AddressForm";
import {mapState} from "vuex";
import AddressMap from "../../../components/AddressMap";
export default {
  name: "AddressDialog",
  components: {AddressMap, AddressForm},
  data() {
    return {
      dialog: false,
      addressPosition: {
        lat: null,
        lng: null
      }
    }
  },
  created() {
    this.addressPosition.lat = this.user.merchant.latitude;
    this.addressPosition.lng = this.user.merchant.longitude;
  },
  computed: {
    ...mapState({
      user: state => state._auth.user
    }),
    isLatLng() {
      return this.lng && this.lat
    },
    address: {
      get() {
        return this.$store.state.order.address;
      },
      set(value) {
        this.$store.commit("updateOrder", {prop: "address", value});
      }
    },
    lat: {
      get() {
        return this.$store.state.order.latitude;
      },
      set(value) {
        this.$store.commit("updateOrder", {prop: "latitude", value});
      }
    },
    lng: {
      get() {
        return this.$store.state.order.longitude;
      },
      set(value) {
        this.$store.commit("updateOrder", {prop: "longitude", value});
      }
    }
  },
  methods: {
    chooseRegisteredAddress() {
      this.dialog = false;
      this.address = this.user.merchant.address;
    },
    save() {
      this.saveAddressToOrder();
      this.dialog = false;
    },
    saveAddressToOrder() {
      this.lat = this.addressPosition.lat;
      this.lng = this.addressPosition.lng;
    }
  },
  watch: {
    addressPosition: {
      handler() {
        this.saveAddressToOrder();
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.validation-message {
  vertical-align: middle;
  line-height: 39px;
  margin-right: 10px;
}
</style>