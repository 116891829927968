<template>
  <div class="extended-forms">
    <slot></slot>
    <div class="row">
      <div class="col-md-12 mt-3">
        <v-text-field
            placeholder="ابحث في الخريطة"
            @change="getLatLng"
            v-model="searchQuery"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-map-search"
        >
        </v-text-field>
        <div id="map"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "AddressMap",
  props: {
    value: {
      required: true,
      type: Object
    },
    mapCenter: {
      type: Object,
      default: function(){
        return {
          lat: 23.8859,
          lng: 45.0792
        }
      }
    }
  },
  data() {
    return {
      searchQuery: "",
      position: this.value
    };
  },
  mounted() {
    this.initMap();
  },
  computed: {
    map() {
      return new this.window.google.maps.Map(document.getElementById("map"), {
        zoom: 7,
        center: {lat: Number(this.mapCenter.lat), lng: Number(this.mapCenter.lng)},
      });
    },
    geocoder() {
      return new this.window.google.maps.Geocoder;
    },
  },
  methods: {
    initMap() {
      // Configure the click listener.
      this.map.addListener("click", (mapsMouseEvent) => {
        this.position.lat = mapsMouseEvent.latLng.lat();
        this.position.lng = mapsMouseEvent.latLng.lng();
        this.addNewMarker(mapsMouseEvent.latLng, this.map);
        this.getAddress(mapsMouseEvent.latLng);
      });
      if (!this.position.lat && !this.position.lng) return;
      let latLng = { lat: Number(this.position.lat), lng: Number(this.position.lng) };
      this.addNewMarker(latLng, this.map);
      this.getAddress(latLng);
    },
    addNewMarker(latLng, map) {
      // remove the last marker
      if (this.marker) this.marker.setMap(null);
      this.marker = new this.window.google.maps.Marker({
        position: latLng,
      });
      this.marker.setMap(map);
    },
    getAddressComponentByType(results, type) {
      for (let result of results) {
        for (let component of result.address_components) {
          if (component.types.includes(type)) {
            return component.short_name;
          }
        }
      }
    },
    districtFallback(results) {
      let fallback = "";
      let fallbackToTypes = ["administrative_area_level_1", "country"];
      for (let addressComponentType of fallbackToTypes) {
        fallback = this.getAddressComponentByType(
          results,
          addressComponentType
        );
        if (fallback) return fallback;
      }
    },
    getAddress(latLng) {
      this.geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.searchQuery = results[0].formatted_address;
            let sublocality = this.getAddressComponentByType(
              results,
              "sublocality"
            );
            let locality = this.getAddressComponentByType(results, "locality");
            this.district =
              (sublocality ? sublocality : "") +
              (sublocality ? ", " : "") +
              locality;
            if (this.district === "undefined") {
              this.district = this.districtFallback(results);
            }
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      });
    },
    getLatLng(searchQuery) {
      if (this.marker) this.marker.setMap(null);
      this.geocoder.geocode({ address: searchQuery }, (results, status) => {
        if (status === "OK") {
          this.position.lat = results[0].geometry.location.lat();
          this.position.lng = results[0].geometry.location.lng();
          this.map.setCenter(results[0].geometry.location);
          this.marker = new this.window.google.maps.Marker({
            map: this.map,
            position: results[0].geometry.location,
          });
        } else {
          alert(
            "Geocode was not successful for the following reason: " + status
          );
        }
      });
    },
  },
  watch: {
    mapCenter: {
      handler() {
        this.position.lat = null;
        this.position.lng = null;
        this.searchQuery = "";
        this.initMap();
      },
      deep: true
    },
    position: {
      handler(value) {
        this.$emit('input', value);
      },
      deep: true
    }
  },
};
</script>

<style scoped>
#map {
  height: 70vh;
}
</style>
