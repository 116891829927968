<template>
 <div>
   <v-row justify="center">
     <v-col cols="9">
       <v-text-field
           color="amber lighten-1"
           v-model="email"
           label="البريد الالكتروني"
           outlined
           shaped
           prepend-inner-icon="mdi-email"
           required
           :error-messages="emailErrors"
           @input="$v.email.$touch()"
           @blur="$v.email.$touch()"
       ></v-text-field>
     </v-col>
   </v-row>
   <v-row justify="center">
     <v-col cols="9">
       <v-text-field
           color="amber lighten-1"
           v-model="password"
           :append-icon="isEyeOpen ? 'mdi-eye' : 'mdi-eye-off'"
           :type="isEyeOpen ? 'text' : 'password'"
           label="كلمة السر"
           @click:append="isEyeOpen = !isEyeOpen"
           outlined
           shaped
           :error-messages="passwordErrors"
           prepend-inner-icon="mdi-lock"
           required
       ></v-text-field>
     </v-col>
   </v-row>
 </div>
</template>

<script>
import {required, email} from "vuelidate/lib/validators";
import { validationMixin } from 'vuelidate'

export default {
  name: "LoginForm",
  mixins: [validationMixin],
  data() {
    return {
      isEyeOpen: false
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required
    }
  },
  computed: {
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('يجب ان يكون بريد الكتروني صالح')
      !this.$v.email.required && errors.push('البريد الالكتروني مطلوب')
      return errors
    },
    passwordErrors () {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('كلمة السر مطلوب')
      return errors
    },
    email: {
      get() {
        return this.$store.state._auth.login.email;
      },
      set(value) {
        this.$store.commit("_auth/updateLogin", {prop: "email", value});
      }
    },
    password: {
      get() {
        return this.$store.state._auth.login.password;
      },
      set(value) {
        this.$store.commit("_auth/updateLogin", {prop: "password", value});
      }
    },
  }
}
</script>

<style scoped>

</style>