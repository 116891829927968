<template>
  <v-row justify="center">
    <v-dialog
        v-model="isSuccessfulOrderDialogOpened"
        persistent
        width="600px"
        height="700px"
    >
      <v-card>
        <v-card-text class="py-10">
          <v-container class="text-center">
            <div>
              <v-icon color="amber lighten-1" class="font-weight-bold text-h2">
                mdi-checkbox-multiple-marked-circle
              </v-icon>
            </div>
            <div class="font-weight-bold text-h5 mt-7">
              طلبك رقم #
              <b class="font-weight-bold mx-2">{{orderId}}</b>
              <br>
              تم وضع طلبك بنجاح
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            اغلاق
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  name: "SuccessfulOrder",
  props: {
    orderId: {
      required: true,
      type: Number
    }
  },
  computed: {
    isSuccessfulOrderDialogOpened: {
      get() {
        return this.$store.state.isSuccessfulOrderDialogOpened;
      },
      set(value) {
        this.$store.commit("updateIsSuccessfulOrderDialogOpened", value);
      }
    }
  },
  methods: {
    close() {
      // this.$router.replace({name: 'home'});
      this.isSuccessfulOrderDialogOpened = false;
    }
  }
}
</script>

<style scoped>

</style>