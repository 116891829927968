<template>
  <v-card width="100%" class="py-6"
          outlined
  >
    <v-card-title class="font-weight-bold mb-5 text-h5">
      العنوان
    </v-card-title>
    <div class="px-10 text-h7">
      <v-row
          justify="start" align="center">
        <v-col cols="4">
          <v-radio-group v-model="addressSelected">
            <v-radio
                class="text--darken-1 font-weight-bold"
                color="amber lighten-1"
                :label="address"
                value="1"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col>
          <address-dialog></address-dialog>
        </v-col>
      </v-row>
    </div>
    <slot></slot>
  </v-card>
</template>

<script>
import AddressDialog from "../../pages/Address/Components/AddressDialog";
import {mapState} from "vuex";
export default {
  name: "AddressCard",
  components: {AddressDialog},
  data() {
    return {
      addressSelected: "1"
    }
  },
  created() {
    this.address = this.user.merchant.address;
  },
  computed: {
    ...mapState({
      user: state => state._auth.user
    }),
    address: {
      get() {
        return this.$store.state.order.address;
      },
      set(value) {
        this.$store.commit("updateOrder", {prop: "address", value});
      }
    }
  }
}
</script>

<style scoped>

</style>