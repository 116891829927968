<template>
  <v-card width="100%" class="py-6"
          height="200"
  >
    <div class="px-10 text-h7">
      <address-form></address-form>
      <v-btn
          class="float-left mt-9"
          color="amber lighten-1"
          @click="submit"
      >
        حفظ
      </v-btn>
    </div>
    <slot></slot>
  </v-card>
</template>

<script>
import AddressForm from "./Components/AddressForm";
export default {
  name: "Address",
  components: {AddressForm},
  methods: {
    submit() {

    }
  }
}
</script>

<style scoped>

</style>