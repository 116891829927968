export default {
  login: {
    email: null,
    password: null
  },
  registration: {
    name_en: null,
    name_ar: null,
    address: null,
    commercial_register: null,
    tax_code: null,
    email: null,
    password: null,
    password_confirmation: null
  },
  user: null
}