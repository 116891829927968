<template>
  <div>
    <v-row justify="center">
      <v-col cols="9">
        <v-text-field

            color="amber lighten-1"
            v-model="name_ar"
            label="الاسم عربي"
            outlined
            shaped
            prepend-inner-icon="mdi-form-textbox"
            required
            :error-messages="nameArErrors"
            @input="$v.name_ar.$touch()"
            @blur="$v.name_ar.$touch()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="9">
        <v-text-field

            color="amber lighten-1"
            v-model="name_en"
            label="الاسم انجليزي"
            outlined
            shaped
            prepend-inner-icon="mdi-form-textbox"
            required
            :error-messages="nameEnErrors"
            @input="$v.name_en.$touch()"
            @blur="$v.name_en.$touch()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="9">
        <v-text-field

            color="amber lighten-1"
            v-model="address"
            label="العنوان"
            outlined
            shaped
            prepend-inner-icon="mdi-map-marker"
            required
            :error-messages="addressErrors"
            @input="$v.address.$touch()"
            @blur="$v.address.$touch()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="9">
        <v-text-field
            color="amber lighten-1"
            v-model="phone"
            label="رقم الجوال"
            outlined
            shaped
            prepend-inner-icon="mdi-cellphone"
            suffix="966"
            dir="ltr"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="9">
        <v-text-field

            color="amber lighten-1"
            v-model="email"
            label="البريد الالكتروني"
            outlined
            shaped
            prepend-inner-icon="mdi-email"
            required
            :error-messages="emailErrors"
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="9">
        <v-text-field

            color="amber lighten-1"
            v-model="password"
            :append-icon="isEyeOpen ? 'mdi-eye' : 'mdi-eye-off'"
            :type="isEyeOpen ? 'text' : 'password'"
            label="كلمة السر"
            @click:append="isEyeOpen = !isEyeOpen"
            outlined
            shaped
            :error-messages="passwordErrors"
            prepend-inner-icon="mdi-lock"
            required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="9">
        <v-text-field

            color="amber lighten-1"
            v-model="password_confirmation"
            :append-icon="isConfirmationEyeOpen ? 'mdi-eye' : 'mdi-eye-off'"
            :type="isConfirmationEyeOpen ? 'text' : 'password'"
            label="تأكيد كلمة السر"
            @click:append="isConfirmationEyeOpen = !isConfirmationEyeOpen"
            outlined
            shaped
            :error-messages="passwordConfirmationErrors"
            prepend-inner-icon="mdi-lock"
            required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="9">
        <v-text-field

            color="amber lighten-1"
            v-model="commercial_register"
            label="رقم السجل التجاري"
            outlined
            shaped
            :error-messages="commercialRegisterErrors"
            prepend-inner-icon="mdi-cash-register"
            required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="9">
        <v-text-field

            color="amber lighten-1"
            v-model="tax_code"
            label="الرقم الضريبي"
            outlined
            shaped
            :error-messages="taxCodeErrors"
            prepend-inner-icon="mdi-barcode"
            required
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {required, email, sameAs} from "vuelidate/lib/validators";

export default {
  name: "RegisterForm",
  data() {
    return {
      isEyeOpen: false,
      isConfirmationEyeOpen: false
    }
  },
  validations: {
    name_ar: {
      required
    },
    name_en: {
      required
    },
    email: {
      required,
      email
    },
    password: {
      required
    },
    commercial_register: {
      required
    },
    tax_code: {
      required
    },
    password_confirmation: {
      required,
      sameAsPassword: sameAs('password')
    },
    address: {
      required
    }
  },
  computed: {
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('يجب ان يكون بريد الكتروني صالح')
      !this.$v.email.required && errors.push('البريد الالكتروني مطلوب')
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('كلمة السر مطلوب')
      return errors
    },
    passwordConfirmationErrors() {
      const errors = []
      if (!this.$v.password_confirmation.$dirty) return errors
      !this.$v.password_confirmation.sameAsPassword && errors.push('كلمة السر غير متطابقة')
      !this.$v.password_confirmation.required && errors.push('كلمة السر مطلوب')
      return errors
    },
    nameArErrors() {
      const errors = []
      if (!this.$v.name_ar.$dirty) return errors
      !this.$v.name_ar.required && errors.push('الاسم عربي مطلوب')
      return errors
    },
    nameEnErrors() {
      const errors = []
      if (!this.$v.name_en.$dirty) return errors
      !this.$v.name_en.required && errors.push('الاسم انجليزي مطلوب')
      return errors
    },
    addressErrors() {
      const errors = []
      if (!this.$v.address.$dirty) return errors
      !this.$v.address.required && errors.push('العنوان مطلوب')
      return errors
    },
    commercialRegisterErrors() {
      const errors = []
      if (!this.$v.commercial_register.$dirty) return errors
      !this.$v.commercial_register.required && errors.push('رقم السجل التجاري مطلوب')
      return errors
    },
    taxCodeErrors() {
      const errors = []
      if (!this.$v.tax_code.$dirty) return errors
      !this.$v.tax_code.required && errors.push('الرقم الضريبي مطلوب')
      return errors
    },
    name_ar: {
      get() {
        return this.$store.state._auth.registration.name_ar;
      },
      set(value) {
        this.$store.commit("_auth/updateRegistration", {prop: "name_ar", value});
      }
    },
    name_en: {
      get() {
        return this.$store.state._auth.registration.name_en;
      },
      set(value) {
        this.$store.commit("_auth/updateRegistration", {prop: "name_en", value});
      }
    },
    address: {
      get() {
        return this.$store.state._auth.registration.address;
      },
      set(value) {
        this.$store.commit("_auth/updateRegistration", {prop: "address", value});
      }
    },
    phone: {
      get() {
        return this.$store.state._auth.registration.phone;
      },
      set(value) {
        this.$store.commit("_auth/updateRegistration", {prop: "phone", value});
      }
    },
    commercial_register: {
      get() {
        return this.$store.state._auth.registration.commercial_register;
      },
      set(value) {
        this.$store.commit("_auth/updateRegistration", {prop: "commercial_register", value});
      }
    },
    tax_code: {
      get() {
        return this.$store.state._auth.registration.tax_code;
      },
      set(value) {
        this.$store.commit("_auth/updateRegistration", {prop: "tax_code", value});
      }
    },
    email: {
      get() {
        return this.$store.state._auth.registration.email;
      },
      set(value) {
        this.$store.commit("_auth/updateRegistration", {prop: "email", value});
      }
    },
    password: {
      get() {
        return this.$store.state._auth.registration.password;
      },
      set(value) {
        this.$store.commit("_auth/updateRegistration", {prop: "password", value});
      }
    },
    password_confirmation: {
      get() {
        return this.$store.state._auth.registration.password_confirmation;
      },
      set(value) {
        this.$store.commit("_auth/updateRegistration", {prop: "password_confirmation", value});
      }
    },
  }
}
</script>

<style scoped>

</style>