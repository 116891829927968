import auth from "../helpers/auth";
import NotFound from "../pages/NotFound";
import Cart from "../pages/Cart";
import Login from "../pages/Auth/Login";
import Profile from "../pages/Profile";
import OrdersHistory from "../pages/OrdersHistory";
import Checkout from "../pages/Checkout";
import Address from "../pages/Address/Address";
import Layout from "../components/Layout";
import AppHeader from "../components/header/AppHeader";
import AuthLayout from "../pages/Auth/AuthLayout";
import Register from "../pages/Auth/Register";
import Home from "../pages/Home";

const routes = [
  {
    path: "/",
    components: {
      default: Layout,
      'app-header': AppHeader
    },
    name: "layout",
    beforeEnter: (to, from, next) => {
      if (!auth.userAuthenticated() || auth.userAuthenticated() === "undefined") {
        next({name: "login"});
      } else {
        next();
      }
    },
    children: [
      {
        path: "",
        component: Home,
        name: "home",
      },
      {
        path: "/cart",
        component: Cart,
        name: "cart",
        meta: {
          crumbs: ["home", "cart"]
        }
      },
      {
        path: "/checkout",
        component: Checkout,
        name: "checkout",
        meta: {
          crumbs: ["home", "cart", "checkout"]
        }
      },
      {
        path: "/profile",
        component: Profile,
        name: "profile",
        meta: {
          crumbs: ["home", "profile"]
        }
      },
      {
        path: "/orders-history",
        component: OrdersHistory,
        name: "orders_history",
        meta: {
          crumbs: ["home", "orders_history"]
        }
      },
      {
        path: "/address",
        component: Address,
        name: "address",
        meta: {
          crumbs: ["home", "address"]
        }
      }
    ]
  },
  {
    path: "",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        component: Login,
        name: "login",
        beforeEnter: (to, from, next) => {
          if (!auth.userAuthenticated() || auth.userAuthenticated() === "undefined") {
            next();
          } else {
            next({name: "home"});
          }
        },
      },
      {
        path: "/register",
        component: Register,
        name: "register"
      },
    ]
  },
  {path: '*', component: NotFound}
];

export default routes;
